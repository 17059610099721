import React, { useState, useContext, useEffect } from "react"
import { Link as LinkComponent } from "gatsby"
import styled, { css } from "styled-components"

// Styling
import { mq } from "../styles/utils/mq"
import { fontWeights } from "../styles/fonts"
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"
import { duration, ease } from "../styles/animations"

// Utils
import { useWheelDirection, useTouchDirection } from "../utils/hooks"
import AppContext from "../utils/context/AppContext"
import PageContext from "../utils/context/PageContext"

// Components
import { Row, Col } from "../components/layout/Grid"
import SEO from "../components/layout/SEO"
import Container from "../components/layout/Container"
import AnimatedScrollWrapper from "../components/animation/AnimatedScrollWrapper"
import AnimatedTextScrollWrapper from "../components/animation/AnimatedTextScrollWrapper"
import LazyImage from "../components/helpers/LazyImage"

// Config
import { config as defaultPageTransitionsConfig } from "../components/pageTransitions/config"

interface ICase {
  client: string
  title: string[]
  image: {
    alt?: string
    url: string
  }
  link: {
    url: string
  }
}

interface IProps {
  data: {
    metaTitle?: string
    metaDescription?: string
  }
  cases: ICase[]
}

// Setup
const ANIMATION_DURATION = 650

const defaults = {
  activeItemIndex: 0,
  isAnimating: false,
}

const pageTransitionsConfig = {
  ...defaultPageTransitionsConfig,
  particle: {
    ...defaultPageTransitionsConfig.particle,
    fillStyle: colors.white,
  },
}

const CasesPage = React.memo(({ data, cases }: IProps) => {
  const app = useContext(AppContext)
  const page = useContext(PageContext)

  // TODO: Get from URL params or use the default
  const [activeItemIndex, setActiveItemIndex] = useState(
    defaults.activeItemIndex
  )
  const [isAnimating, setIsAnimating] = useState(defaults.isAnimating)
  const {
    isInert: isWheelInert,
    direction: wheelDirection,
  } = useWheelDirection()
  const {
    isInert: isTouchInert,
    direction: touchDirection,
  } = useTouchDirection()

  // Utils
  const goToCase = (index: number) => {
    if (index === activeItemIndex) {
      return
    }

    if (index < 0 || index >= cases.length) {
      return
    }

    setActiveItemIndex(index)
    setIsAnimating(true)

    // Unlock scrolling just before the animation ends
    // to give a more organic feeling when cycling through items quickly
    setTimeout(() => setIsAnimating(false), ANIMATION_DURATION - 150)
  }

  const handleDirection = (newDirection: any) => {
    // Prevent additional swipes during a running animation
    if (isAnimating) {
      return
    }

    if (!newDirection) {
      return
    }

    // Prevent overshooting
    if (
      (activeItemIndex === 0 && newDirection === "up") ||
      (activeItemIndex === cases.length - 1 && newDirection === "down")
    ) {
      return
    }

    const newActiveItemIndex =
      newDirection === "up" ? activeItemIndex - 1 : activeItemIndex + 1

    goToCase(newActiveItemIndex)
  }

  useEffect(() => {
    page.updateColor(colors.black)
    page.updateTransition(pageTransitionsConfig)

    if (!isWheelInert) {
      handleDirection(wheelDirection)
    }

    if (!isTouchInert) {
      handleDirection(touchDirection)
    }

    app.lockOverflow()

    return () => {
      app.unlockOverflow()
    }
  }, [isWheelInert, isTouchInert])

  return (
    <>
      <SEO title={data.metaTitle} description={data.metaDescription} />

      {cases && cases.length && (
        <>
          <CaseList activeItemIndex={activeItemIndex}>
            {cases.map((item: ICase, index: number) => (
              <CaseItem key={index}>
                {item.image && item.image.url && (
                  <ImageFrame>
                    <ImageWrapper>
                      <Image src={item.image.url} alt={item.image.alt} />

                      {item.link && item.link.url && (
                        <Link
                          to={item.link.url}
                          aria-label={`Go to case: ${item.title}`}
                        >
                          <AnimatedScrollWrapper>
                            See the case
                          </AnimatedScrollWrapper>
                        </Link>
                      )}
                    </ImageWrapper>
                  </ImageFrame>
                )}

                <Container>
                  {item.link && item.link.url ? (
                    <LinkRow
                      to={item.link.url}
                      aria-label={`Go to case: ${item.title}`}
                    >
                      <Row>
                        <Col m={16} l={12} lOffset={2}>
                          {item.client && (
                            <Client>
                              <AnimatedScrollWrapper>
                                {item.client}
                              </AnimatedScrollWrapper>
                            </Client>
                          )}

                          {item.title && (
                            <Title>
                              <AnimatedTextScrollWrapper
                                text={item.title}
                                textStyles={textStyles.headingL}
                              />
                            </Title>
                          )}
                        </Col>
                      </Row>
                    </LinkRow>
                  ) : (
                    <Row>
                      <Col m={16} l={12} lOffset={2}>
                        {item.client && (
                          <Client>
                            <AnimatedScrollWrapper>
                              {item.client}
                            </AnimatedScrollWrapper>
                          </Client>
                        )}

                        {item.title && (
                          <Title>
                            <AnimatedTextScrollWrapper
                              text={item.title}
                              textStyles={textStyles.headingL}
                            />
                          </Title>
                        )}
                      </Col>
                    </Row>
                  )}
                </Container>
              </CaseItem>
            ))}
          </CaseList>

          <Pager isHidden={activeItemIndex === cases.length - 1 ? true : false}>
            <PagerIndicator>
              <span></span>
            </PagerIndicator>

            <PagerNav>
              <PagerItemsWrapper>
                <PagerLabel>More cases</PagerLabel>

                <PagerList tabIndex={-1}>
                  {cases.map((item: ICase, index: number) => (
                    <PagerItem key={index}>
                      <PagerButton onClick={() => goToCase(index)}>
                        {item.client}
                      </PagerButton>
                    </PagerItem>
                  ))}
                </PagerList>
              </PagerItemsWrapper>
            </PagerNav>
          </Pager>
        </>
      )}
    </>
  )
})

// Shared styles
const sharedPagerBtnStyles = css`
  padding: 0;
  border: none;
  background: none;
  color: ${colors.white};
  cursor: pointer;
`

// Main styles
const CaseList = styled.ul<{ activeItemIndex: number }>`
  list-style: none;
  background-color: ${colors.black};
  margin-left: 0;
  /* Offset automatic layout padding */
  margin-bottom: calc(var(--base-column-size) * -1);

  /* Target all hover supporting browsers. For more info, see: */
  /* https://stackoverflow.com/questions/40532204/media-query-for-devices-supporting-hover */
  @media not all and (hover: none) {
    margin-top: calc(var(--base-column-size) * -1);
    transition: transform ${ANIMATION_DURATION}ms ${ease.cubic};
    transform: ${(props: { activeItemIndex: number }) =>
      `translateY(calc(-1 * ${props.activeItemIndex} * 100vh))`};
  }
`

const CaseItem = styled.li`
  position: relative;
  width: 100%;
  height: 100vh;
  margin-bottom: 0;
  padding-top: calc(var(--base-column-size) + 16px);
  transition: transform ${ANIMATION_DURATION}ms ${ease.cubic};

  @media not all and (hover: none) {
    display: flex;
    align-items: center;
    padding-top: 0;
  }
`

const ImageFrame = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  display: flex;
  align-items: center;
  width: calc(8 * var(--base-column-size));
  height: 100%;
  padding-top: calc(1.75 * var(--base-column-size));
  padding-bottom: calc(1.75 * var(--base-column-size));
  transform: translateY(-50%);

  @media not all and (hover: none) {
    width: calc(8 * var(--base-column-size));
    padding-right: calc(2 * var(--base-column-size));
  }
`

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const Image = styled(LazyImage)`
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: right center;
`

const Link = styled(LinkComponent)`
  ${textStyles.body};
  position: absolute;
  right: var(--base-column-size);
  bottom: calc(-1 * var(--base-column-size));
  color: ${colors.white};
  text-decoration: none;
  transform: translateY(100%);

  @media not all and (hover: none) {
    right: 0;
  }

  &:hover {
    text-decoration: underline;
  }
`

const Client = styled.h6`
  ${textStyles.body};
  margin-bottom: 16px;
  color: ${colors.white};
  font-weight: ${fontWeights.medium};

  @media not all and (hover: none) {
    margin-bottom: 40px;
  }
`

const Title = styled.h1`
  color: ${colors.white};
`

const LinkRow = styled(LinkComponent)`
  color: ${colors.white};
  text-decoration: none;

  &:hover {
    ${Title} {
      text-decoration: none;
    }
  }
`

const Pager = styled.div<{ isHidden: boolean }>`
  position: fixed;
  left: var(--base-column-size);
  bottom: 0;
  display: flex;

  color: ${colors.white};
  transform-origin: top left;
  /* Subtract half of the line height to the left */
  transform: translate(-0.65em, 100%) rotate(-90deg);
  transition: visibility ${duration.fast}ms ${ease.default},
    opacity ${duration.fast}ms ${ease.default};

  ${({ isHidden }) =>
    isHidden
      ? `
        visibility: hidden;
        opacity: 0;
      `
      : `
        visibility: visible
        opacity: 1
      `}

  @media not all and (hover: none) {
    left: calc(var(--base-column-size) / 2);
    visibility: visible;
    opacity: 1;
  }
`

const PagerIndicator = styled.div`
  display: flex;
  align-items: center;
  height: 1.3em;
  margin-right: 16px;

  span {
    position: relative;
    top: 1px;
    width: 48px;
    height: 1px;
    background-color: ${colors.white};
  }
`

const PagerList = styled.ul`
  display: none;

  @media not all and (hover: none) {
    display: block;
    margin: 0;
    list-style: none;
    line-height: 1.3;
    opacity: 0;
    visibility: hidden;
    transition: visibility ${duration.medium}ms ${ease.cubic},
      opacity ${duration.medium}ms ${ease.cubic},
      transform ${duration.medium}ms ${ease.cubic};
  }
`

const PagerItem = styled.li`
  margin-bottom: 0;
`

const PagerLabel = styled.div`
  ${sharedPagerBtnStyles};
  /* Make the touch area slightly larger on mobile */
  padding: 16px 0;
  transform: translateY(-16px);
  transition: visibility ${duration.medium}ms ${ease.cubic},
    opacity ${duration.medium}ms ${ease.cubic};

  ${mq.from.L`
    padding: 0;
    transform: none;
  `}

  @media (hover: none) {
    pointer-events: none;
  }
`

const PagerButton = styled.button`
  ${sharedPagerBtnStyles};
  transition: transform ${duration.fast}ms ${ease.default};

  @media (hover: hover) {
    &:hover {
      transform: translateX(8px);
    }
  }
`

const PagerItemsWrapper = styled.div`
  transition: transform ${duration.medium}ms ${ease.cubic};
`

const PagerNav = styled.nav`
  @media (hover: hover) {
    &:hover {
      ${PagerItemsWrapper} {
        ${mq.from.L`
            transform: translateY(-1.3em);
          `}
      }

      ${PagerLabel} {
        ${mq.from.L`
            opacity: 0;
            visibility: hidden;
          `}
      }

      ${PagerList} {
        ${mq.from.L`
            opacity: 1;
            visibility: visible;
          `}
      }
    }
  }
`

export default CasesPage
