import React from "react"
import { graphql } from "gatsby"

// Components
import CasesPage from "../pageViews/casesPageView"

const CasesPageContainer = ({ data }: any) => {
  if (!data.caseOverview) {
    return null
  }

  const res = data.caseOverview.data

  const formattedData = {
    metaTitle: res.metaTitle,
    metaDescription: res.metaDescription,
  }

  const formattedCases = res.cases.map((item: any) => {
    return {
      ...item,
      title: item.title.raw.map((line: any) => line.text),
      link: {
        url: `/case/${item.link.uid}`,
      },
    }
  })

  return <CasesPage data={formattedData} cases={formattedCases} />
}

export const query = graphql`
  query GetCases {
    caseOverview: prismicCaseOverview {
      data {
        metaTitle: meta_title
        metaDescription: meta_description
        cases {
          image: case_image {
            alt
            url
          }
          title: case_title {
            raw {
              text
            }
          }
          client
          link: link_to_case {
            uid
          }
        }
      }
    }
  }
`

export default CasesPageContainer
